import React, { Component } from 'react';
import DodoComponent from './DodoComponent';


class Banner extends Component {
  
  render(){
      var imgStyle = {
          maxHeight: '100px',
          margin: '0 auto'
      };

      return (
          <DodoComponent fullComponentClickable="false" show="true">
              <div id="main-img" style={imgStyle} />
          </DodoComponent>
      )
  }
}

export default Banner;