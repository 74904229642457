import React, { Component } from 'react';
import CommonDodo from './CommonDodo';
import DodoComponent from './DodoComponent';


class Search extends Component {
  
  constructor() {
    super();
    
    this.state = {
      value: ''
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
    
	handleChange(event) {
		this.setState({value: CommonDodo.xbtarget(event).value});
	}
    
	handleSubmit(event){
		event.preventDefault();
        
        var query = this.state.value.trim();
        if (query.length === 0) return; // ignore empty search
		this.props.onSearch(query);
        
        var inputField = CommonDodo.xbtarget(event).querySelector('input');
        if (!inputField) inputField = CommonDodo.xbtarget(event).parentNode.parentNode.querySelector('input');
        inputField.blur(); // unfocus input field
	}
    
	render() {
        
        if (!this.props.show) return null;
        
		return (
            <DodoComponent fullComponentClickable="false" style="noborder" show="true">
            {
			<form id="searchForm" className="form-horizontal" onSubmit={this.handleSubmit}>
                <div className="input-group">
                    <input type="text" className="form-control" id="searchField" placeholder="Search for bar, club, gym, beach, cruising..." value={this.state.value} onChange={this.handleChange}></input>
                    <div className="input-group-btn">
                        {<span className="glyphicon glyphicon-search" style={{fontSize: '20px', color:'#969FA8'}} aria-hidden="true" onClick={this.handleSubmit}></span>}
                    </div>
                </div>
                </form>
            }
            
            {/*
            <div xxxxxxxxxxxstyle={{XXXXXXbackgroundColor: '#aaaaff', width:'100%'}}>
                <div xxxxxxxxxstyle={{backgroundColor: '#ffaaaa'}} className="input-group col-xs-12">
                    <input type="text" className="form-control" id="searchField" placeholder="Search for bar, club, gym, beach, cruising..." style={{backgroundColor: '#aaffaa',  width:'100%' }} value={this.state.value} onChange={this.handleChange}></input>
                    <span className="input-group-btn">
                        {<span className="glyphicon glyphicon-search" style={{position: 'relative', fontSize: '20px', right: '130px'}} aria-hidden="true" onClick={this.handleSubmit}></span>}
                    </span>
                </div>
            </div>
            */}
            
            </DodoComponent>
		);
        
	}
}

export default Search;