import React, { Component } from 'react';
//import GenericBase from './GenericBase';
import Fetch from 'whatwg-fetch';
import CommonDodo from './CommonDodo';
import DetailView from './DetailView';
import InputComment from './InputComment';
import Map from './Map';
import Guide from './Guide';
import News from './News';
import Banner from './Banner';
import Search from './Search';
import LocationList from './LocationList';
import ShareLink from './ShareLink';
import DodoComponent from './DodoComponent';
import SubmitLocation from './SubmitLocation';

import headerImage from '../../icons/web_header.png';

const serverHost = (process.env.NODE_ENV !== 'production') ? 'http://localhost:3000' : 'https://www.gaydodo.com/dserv';
//const serverHost = (process.env.NODE_ENV !== 'production') ? 'http://localhost:3000' : 'https://www.gaydodo.com/dserv';


function readLocalFile(file, callback) {
    var rawFile = new XMLHttpRequest();
    rawFile.overrideMimeType("application/json");
    rawFile.open("GET", file, true);
    rawFile.onreadystatechange = function() {
        if (rawFile.readyState === 4 && rawFile.status == "200") {
            callback(rawFile.responseText);
        }
    }
    rawFile.send(null);
}

function parseUser(json) {
    var userid = json.user;
    if (userid) {
        try {
            localStorage.setItem('dodo-userid', userid);
        }
        catch(err) {
            console.log('Error using local storage');
        }
    }
}

function dodoservFetchContentType(path, method, contentType, content) {
    var headers = {'Accept': 'application/json', 'Content-Type': contentType};
    
    try {
        var dodoUserid = localStorage.getItem('dodo-userid');
        var dodoPosition = localStorage.getItem('dodo-position');
        if (dodoUserid && dodoPosition) {
            dodoUserid = dodoUserid + ',' + dodoPosition;
            localStorage.removeItem('dodo-position'); // attach only once
        }
        if (dodoUserid) path = path + '/' + dodoUserid;
    }
    catch(err) {
        console.log('Error using local storage');
    }
    
    let request = { method: method, headers: headers };
    if (content) request['body'] = content;
//    console.log('FETCH', serverHost + path);
    return fetch(serverHost + path, request)
    .then(function(response) {
      if (!response) throw 'No fetch response';
      else if (!response.ok) throw 'Request returns ' + response.status + ' ' + response.statusText;
      return Promise.resolve(response);
    });
}

function dodoservFetch(path, method, jsonContent) {
    var content = null;
    if (jsonContent) content = JSON.stringify(jsonContent);
    return dodoservFetchContentType(path, method, 'application/json', content);
}


// user has specified some query in URL, try to find guide or location (shortname or hid) matching that query and display guide or detailed location instead of main page
function retrieveLocationGuide(self, query) {
    dodoservFetch('/locationGuide/' + query, 'GET', null)
    .then(function(response) {
        return response.json();
    })
    .then(function(content){
        parseUser(content);
        
        if (content.error) throw 'Not found'; // throw to catch clause
        
        // found location that matches hid or shortname
        if (content.entity === 'entry') {
            self.displayLocation(content);
//            var state = self.state;
//            state.mapCenterZoom = {latitude:content.latitude, longitude:content.longitude}; // TODO Manage map differently
            // NOTE: Render in DetailView currently executed twice due to setState below
//            self.setState(state);
        }
        // found guide that matches name
        else if (content.entity === 'guide') {
            /*var state = self.state;
            var latlngs = content.perimeter.split(',');
            if (latlngs.length===4) {
                state.perimeter = [{lat:parseFloat(latlngs[0]), lng:parseFloat(latlngs[2])}, {lat:parseFloat(latlngs[1]), lng:parseFloat(latlngs[3])}];
            }
            self.setState(state);
            // TODO retrievelocationsinarea is no longer necessary, is now integrated in /locationGuide
            var backText = retrieveLocationsInArea(self, state.perimeter, query);
            */
            self.displayGuide(content);
        }
    })
    .catch(function(error) {
        console.error('Got an error: ', error);
    });
}

/*
function retrieveLocationsInArea(self, perimeter, searchAround) {
    
    dodoservFetch('/locations/area/' + perimeter[0].lat + '/' + perimeter[1].lat + '/' + perimeter[0].lng + '/' + perimeter[1].lng, 'GET', null)
    .then(function(response) {
        return response.json();
    })
    .then(function(json){
//                console.log(util.inspect(json, {showHidden: false, depth: null}))
        parseUser(json);
        var showLocationList = false;
        var backText = 'Go back to ' + searchAround + ' gay guide';
        if (json && !json.error) {
            if (json.length > 0) {
                var state = self.state;
                state.searchResultLocations = json;
                state.searchResultTitle = 'Got ' + (state.searchResultLocations ? state.searchResultLocations.length : 0) + ' spots around ' + searchAround + ' for you';
                showLocationList = true;
                backText = backText + ', there are ' + (state.searchResultLocations.length-1) + ' other spots in ' + searchAround + '!';
                self.setState(state);
            }
        }
        
        //self.setVisibilityFlags({news: false, guide: true, locationList: showLocationList, locationDetail: false, navigator: true}, backText, null);
        return backText;
    })
    .catch(function(error) {
        console.error('Got an error: ', error);
    });
}
*/

function postComment(self, comment) {
    var hid = self.state.visibilityFlags.selectedLocation.hid;
    var content = {comment: comment};
    
    dodoservFetch('/locations/' + hid + '/comments', 'POST', content)
    .then(function(response) {
        return response.json();
    })
    .then(function(json){
        // console.log(util.inspect(json, {showHidden: false, depth: null}))
        parseUser(json);
        if (json.success) {
            // do nothing, request is fire and forget
        }
    })
    .catch(function(error) {
        console.error('Got an error: ', error);
    });
}


class App extends Component {
  
  constructor() {
    super();
    
    this.visibilityFlagsStack = [];
    this.gmap = null;
    
    this.state = {
      allLocations: null ,
      showPermaLink: false,
      promoStories: null,
      wordcloud: null,
      searchResponse: null,
      visibilityFlags: { news: true, search: true, guide: false, locationList: false, locationDetail: false, navigator: false, map: true, submitLocation: false, backText: null, lastPageOffset: -1, selectedLocation: null, selectedGuide: null, selectedLocationList: null, mapPerimeter: { initialMapPerimeter: 1} },
      navigatorNextBackText: 'Back to main site'
    };
    
    this.handlePostComment = this.handlePostComment.bind(this);
    this.handlePostImage = this.handlePostImage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.retrieveSingleLocation = this.retrieveSingleLocation.bind(this);
    this.retrieveSingleGuide = this.retrieveSingleGuide.bind(this);
    this.displaySubmitLocationForm = this.displaySubmitLocationForm.bind(this);
    this.toggleShowPermaLink = this.toggleShowPermaLink.bind(this);
    this.handleLikeLocation = this.handleLikeLocation.bind(this);
    this.setGmap = this.setGmap.bind(this);
    this.setVisibilityFlags = this.setVisibilityFlags.bind(this);
    this.restoreVisibilityFlags = this.restoreVisibilityFlags.bind(this);
  }
  
  
  setVisibilityFlags(newVisibilityFlags, title, navigatorBackText, selectedLocation, selectedGuide, selectedLocationList, mapPerimeter) {
        // ignore mapPerimeter, if single position has been specified and position already shows on the map
        if (this.gmap && this.gmap.getZoom() >= 11 && mapPerimeter.centerPosition) {
            if (this.gmap.getBounds().contains(mapPerimeter.centerPosition)) mapPerimeter = {};
        }
        
        var state = this.state;
        
        // if no change in visibility configuration, ignore and update location or guide if necessary
        if (state.visibilityFlags.news === newVisibilityFlags.news && state.visibilityFlags.search === newVisibilityFlags.search && state.visibilityFlags.guide === newVisibilityFlags.guide && state.visibilityFlags.locationList === newVisibilityFlags.locationList && state.visibilityFlags.locationDetail === newVisibilityFlags.locationDetail && state.visibilityFlags.navigator === newVisibilityFlags.navigator && state.visibilityFlags.map === newVisibilityFlags.map && state.visibilityFlags.submitLocation === newVisibilityFlags.submitLocation)
        {
//        if (JSON.stringify(this.state.visibilityFlags) === JSON.stringify(newVisibilityFlags)) {
            state.visibilityFlags.selectedLocation = selectedLocation; // at least update the currently selected location
            state.visibilityFlags.selectedGuide = selectedGuide;
            state.visibilityFlags.selectedLocationList = selectedLocationList;
            state.visibilityFlags.mapPerimeter = mapPerimeter;
            this.setState(state);
            return;
        }
        
        state.visibilityFlags.lastPageOffset = window.pageYOffset; // attach current scroll offset to old state
        state.visibilityFlags.lastTitle = document.title;
        state.visibilityFlags.lastPerimeter = { centerPosition: { lat:this.gmap.getCenter().lat(), lng:this.gmap.getCenter().lng(), zoom:this.gmap.getZoom() }}; //state.visibilityFlags.mapPerimeter;
        this.visibilityFlagsStack.push(state.visibilityFlags); // store old state
        
        state.visibilityFlags = newVisibilityFlags; // establish new state
        state.visibilityFlags.backText = state.navigatorNextBackText;
        state.visibilityFlags.selectedLocation = selectedLocation;
        state.visibilityFlags.selectedGuide = selectedGuide;
        state.visibilityFlags.selectedLocationList = selectedLocationList;
        state.visibilityFlags.mapPerimeter = mapPerimeter;
        state.navigatorNextBackText = navigatorBackText; // will be relevant if we navigate forward to another site
        
        document.title = title;
        state.searchResponse = null; // reset on every new page view
        
        this.setState(state);
    }
    
    restoreVisibilityFlags() {
        if (this.visibilityFlagsStack.length === 0) return;
        
        var state = this.state;
        state.navigatorNextBackText = state.visibilityFlags.backText;
        state.visibilityFlags = this.visibilityFlagsStack.pop();
        this.setState(state);
        
        if (state.visibilityFlags.lastTitle) document.title = state.visibilityFlags.lastTitle;
        
        if (state.visibilityFlags.lastPageOffset >= 0) {
            CommonDodo.onNextFrame(function() { // restore scroll offset
                window.scrollTo(0, state.visibilityFlags.lastPageOffset);
            });
        }
        
        if (state.visibilityFlags.lastPerimeter) state.visibilityFlags.mapPerimeter = state.visibilityFlags.lastPerimeter;
        
    }
    
    componentWillMount() {
        // retrieve query if specific in url
        var query = window.location.search.substr(1); // specified through ?somequery
        if (!query) query = window.location.pathname.split('/')[1]; // specified through /somequery
        if (query.toLowerCase().endsWith('-guide')) { // TODO Remove this
            query = query.substring(0, query.length-6); // remove '-guide' from query
        }
        CommonDodo.queryFromUrl = query; // save query globally
    }
    
    componentDidMount() {
        var self = this;
        readLocalFile('/staticdata.json', function(text){
            var data = JSON.parse(text);
            var state = self.state;
            if (data.locations) {
                state.allLocations = data.locations;
                if (data.locations.length > 0) state.navigatorNextBackText = 'Back to main site, discover ' + CommonDodo.formatBigNumber(data.locations.length-1) + ' other spots!';
            }
            if (data.promo) state.promoStories = data.promo;
            // if (data.wordcloud) state.wordcloud = data.wordcloud;
            self.setState(state);
        });
        
        // retrieve guide if specific guide has been asked for
        // var guidequery = window.location.search.substr(1); // specified through ?guidename
        // if (!guidequery) guidequery = window.location.pathname.split('/')[1]; // specified through /guidename
        if (CommonDodo.queryFromUrl) {
            // normalize letter case: Turn amsTeRdaM into Amsterdam
            var query = CommonDodo.queryFromUrl.substr(0,1).toUpperCase() + CommonDodo.queryFromUrl.substr(1).toLowerCase();
            retrieveLocationGuide(self, query);
        }
    }
    
    handlePostComment(newComment, callback) {
        var hid = this.state.visibilityFlags.selectedLocation.entry.hid;
        var content = {comment: newComment};
        dodoservFetch('/locations/' + hid + '/comments', 'POST', content)
        .then(function(response) {
            return response.json();
        })
        .then(function(json){
            parseUser(json);
            if (json.success) {
              callback(true, null);
            }
        })
        .catch(function(error) {
            console.error('Got an error: ', error);
            callback(null, error);
        });
    }
    
    handlePostImage(image, callback) {
        var hid = this.state.visibilityFlags.selectedLocation.entry.hid;
        dodoservFetchContentType('/locations/' + hid + '/photos', 'POST', 'application/octet-stream', image)
        .then(function(response) {
            return response.json();
        })
        .then(function(json){
            parseUser(json);
            if (json.success) {
              callback(true, null);
            }
        })
        .catch(function(error) {
            console.error('Got an error: ', error);
            callback(null, error);
        });
    }
    
    handleSearch(query) {
        var state = this.state;
        state.searchResponse = null;
        this.setState(state);
        
        var self = this;
        dodoservFetchContentType('/search', 'POST', 'text/plain', query)
        .then(function(response) {
            return response.json();
        })
        .then(function(content){
            parseUser(content);
            if (content.error) throw 'Not found'; // throw to catch clause
            self.displaySearchResult(content);
        })
        .catch(function(error) {
            console.error('Got an error: ', error);
        });
    }
    
    retrieveSingleLocation(hid) {
        var self = this;
        // TODO: Error handling, if location not found
        
        dodoservFetch('/locations/' + hid, 'GET', null)
        .then(function(response) {
            return response.json();
        })
        .then(function(content){
            parseUser(content);
            if (content.error) throw 'Not found';
            self.displayLocation(content);
        })
        .catch(function(error) {
            console.error('Got an error: ', error);
        });
    }
    
    retrieveSingleGuide(id) {
        var self = this;
        //var selectedLocation = this.state.allLocations.find(x => x.hid === hid);
        
        dodoservFetch('/guides/' + id, 'GET', null)
        .then(function(response) {
            return response.json();
        })
        .then(function(content){
            parseUser(content);
            if (content.error) throw 'Not found';
            self.displayGuide(content);
        })
        .catch(function(error) {
            console.error('Got an error: ', error);
        });
    }
    
    displayLocation(content) {
        var perimeter = {centerPosition: {lat:content.entry.latitude, lng:content.entry.longitude, zoom:11}};
        this.setVisibilityFlags({news: false, search: false, guide: false, locationList: false, locationDetail: true, navigator: true, map: true, submitLocation: false}, content.entry.name + ' - Gay Dodo', 'Back to ' + content.entry.name, content, null, null, perimeter);
    }
    
    displayGuide(content) {
        var locationList = {
            locations: content.locations,
            guides: null,
            title: 'Got ' + content.locations.length + ' spots around ' + content.guide.name + ' for you'
        };
        var perimeter = {areaBounds: [{lat:content.guide.lat1, lng:content.guide.lon1}, {lat:content.guide.lat2, lng:content.guide.lon2}]};
        this.setVisibilityFlags({news: false, search: false, guide: true, locationList: true, locationDetail: false, navigator: true, map: true, submitLocation: false}, content.guide.name + ' guide - Gay Dodo', 'Go back to ' + content.guide.name + ' gay guide, we\'ve got ' + (content.locations.length-1) + ' other spots', null, content.guide, locationList, perimeter);
//        window.location.pathname = '/' + content.guide.name.toLowerCase();
    }
    
    displaySearchResult(content) {
        // did not find anything
        if (content.entries.length + content.guides.length === 0) {
            var state = this.state;
            state.searchResponse = 'Did not find anything, try again';
            this.setState(state);
            return;
        }
        
        // found exactly one
        else if (content.entries.length + content.guides.length === 1) {
            if (content.entries.length === 1) this.retrieveSingleLocation(content.entries[0].hid);
            else if (content.guides.length === 1) this.retrieveSingleGuide(content.guides[0].id);
            return;
        }
        
        // found more than one
        var title = 'Found ' + (content.entries.length + content.guides.length) + ' spot' + ((content.entries.length + content.guides.length > 1) ? 's' : '');
        if (content.entries.length >= 50 || content.guides.length >= 50) title = 'Too many results, will only show 50'
        
        var locationList = {
            locations: content.entries,
            guides: content.guides,
            title: title
        };
        
        var perimeter = {}; // ignore perimeter
        this.setVisibilityFlags({news: false, search: true, guide: false, locationList: true, locationDetail: false, navigator: true, map: false, submitLocation: false}, 'Search results - Gay Dodo', 'Back to search results', null, null, locationList, perimeter);
    }
    
    displaySubmitLocationForm() {
        var perimeter = {}; // ignore perimeter
        this.setVisibilityFlags({news: false, search: false, guide: false, locationList: false, locationDetail: false, navigator: false, map: false, submitLocation: true}, 'Gay Dodo - Submit a new gay spot', 'Go back', null, null, null, perimeter);
//        window.location.pathname = '/' + content.guide.name.toLowerCase();
    }
    
    toggleShowPermaLink() {
        var state = this.state;
        state.showPermaLink = !state.showPermaLink;
        this.setState(state);
    }
    
    setGmap(gmap) {
        this.gmap = gmap;
    }
    
    handleLikeLocation() {
        // change like status without server roundtrip
        var state = this.state;
        var youLiked = state.visibilityFlags.selectedLocation.youLike;
        if (youLiked) {
            state.visibilityFlags.selectedLocation.youLike = 0;
            if (state.visibilityFlags.selectedLocation.entry.likes > 0)
                state.visibilityFlags.selectedLocation.entry.likes--;
        }
        else {
            state.visibilityFlags.selectedLocation.youLike = 1;
            state.visibilityFlags.selectedLocation.entry.likes++;
        }
        this.setState(state);
        
        // inform server
        var location = state.visibilityFlags.selectedLocation.entry;
        dodoservFetch('/locations/' + location.hid + '/like/' + (youLiked ? '0' : '1'), 'POST', null)
        .then(function(response) {
            return response.json();
        })
        .then(function(content){
            parseUser(content);
        })
        .catch(function(error) {
            console.error('Got an error: ', error);
        });
    }
    
	render(){
        
        var logoStyle = {
            maxWidth: '320px',
            margin: '0 auto',
            padding: '0 0 0px 0',
            width: '100%'
        };
        
        var style = {
            height: '100pt',
            color: '#000000',
            fontSize: '14pt',
            textAlign: 'left',
            paddingLeft: '7pt'
        };
        
        var iconStyle = { position: 'absolute', top: 0, left: 0, width: '18px' };
        
		return (
            
			<div className="row">
                
                {
                <div className={CommonDodo.standardCols} style={{marginBottom: 0}}>
                    <a href="/">
                        <img className="img-responsive" src={headerImage} alt=" Gay Dodo" style={logoStyle} />
                    </a>
                    <p style={{fontSize: '14pt'}}>We're everywhere.</p>
                </div>
                }
                
                {/*<DodoComponent onClick={this.DEBUG_print_local_userid} show={true}>
                    <div>Example without button</div>
                </DodoComponent>
                <DodoComponent onClick={this.DEBUG_print_local_userid} button="left" buttonWidth="45px" fullComponentClickable="true" show={true}>
                    <div>Example with button on the left side</div>
                    <div className="glyphicon vertically-centered glyphicon-menu-left"></div>
                </DodoComponent>
                <DodoComponent onClick={this.DEBUG_print_local_userid} button="right" buttonWidth="100px" fullComponentClickable="false" show={true}>
                    <div>Example with button on the right side</div>
                    <div className="text-button">Submit</div>
                </DodoComponent>*/}
                
                {/*<div className={CommonDodo.standardCols + ' teststyle'}>Test</div>
                <div className={CommonDodo.standardCols + ' visible-xs'}>XS</div>
                <div className={CommonDodo.standardCols + ' visible-md'}>MD</div>
                <div className={CommonDodo.standardCols + ' visible-lg'}>LG</div>
                <div className={CommonDodo.standardCols + ' visible-sm'}>SM</div>*/}
                
                <DodoComponent onClick={this.restoreVisibilityFlags} button="left" buttonWidth="45px" fullComponentClickable="true" mainNavigation={1} show={this.state.visibilityFlags.navigator}>
                    <div>{this.state.visibilityFlags.backText}</div>
                    <div className="glyphicon vertically-centered glyphicon-menu-left"></div>
                </DodoComponent>
                
                
            {/*
                <DodoComponent style="noborder" show="true">
                <div style={TEST_STYLE}>
                Test2
                </div>
                </DodoComponent>
                
                <DodoComponent style="highlight" show="true">
                <div style={TEST_STYLE}>
                Test3
                </div>
                </DodoComponent>
            */}
                
                {this.state.searchResponse && 
                    <DodoComponent style='noborder' show='true'>{this.state.searchResponse}</DodoComponent>
                }
                {
                <Search onSearch={this.handleSearch} show={this.state.visibilityFlags.search} />
                }
                
                {/*
                <Banner />s
                */}
                
                {<News stories={this.state.promoStories} onSelectLocation={this.retrieveSingleLocation} onSelectGuide={this.retrieveSingleGuide} show={this.state.visibilityFlags.news} />}
                
                <Guide guide={this.state.visibilityFlags.selectedGuide} onLinkTap={this.retrieveSingleLocation} show={this.state.visibilityFlags.guide} serverHost={serverHost}/>
                
                <DetailView location={this.state.visibilityFlags.selectedLocation} onShare={this.toggleShowPermaLink} onLikeLocation={this.handleLikeLocation} show={this.state.visibilityFlags.locationDetail} serverHost={serverHost} />
                <InputComment type="comment" onPost={this.handlePostComment} show={this.state.visibilityFlags.locationDetail} />
                <InputComment type="image" onPost={this.handlePostImage} show={this.state.visibilityFlags.locationDetail} />
                
                {<Map locations={this.state.allLocations} perimeter={this.state.visibilityFlags.mapPerimeter} selectedLocation={this.state.visibilityFlags.selectedLocation ? this.state.visibilityFlags.selectedLocation.entry : null} onLocationTap={this.retrieveSingleLocation} show={this.state.visibilityFlags.map} setGmap={this.setGmap} />}
                
                <LocationList content={this.state.visibilityFlags.selectedLocationList} show={this.state.visibilityFlags.locationList} onSelectLocation={this.retrieveSingleLocation} onSelectGuide={this.retrieveSingleGuide} />
                
                {/* <WordCloud words={this.state.wordcloud} /> */}
                
                <ShareLink show={this.state.showPermaLink} location={this.state.visibilityFlags.selectedLocation} link={(this.state.visibilityFlags.selectedLocation && this.state.visibilityFlags.selectedLocation.entry) ? (window.location.origin + '/' + this.state.visibilityFlags.selectedLocation.entry.shortname) : null} onClose={this.toggleShowPermaLink} />
                
                {/* <DodoComponent onClick={this.displaySubmitLocationForm} show={!this.state.visibilityFlags.submitLocation} button='left' fullComponentClickable='true'>
                  <div>Show some love and add your favorite location</div>
                  <div className="glyphicon vertically-centered glyphicon-heart" style={{color:'#ff0000'}} />
                </DodoComponent>
                
                <SubmitLocation show={this.state.visibilityFlags.submitLocation} /> */}
                
			</div>
		);
	}
}

export default App;