import React, { Component } from 'react';


class Modal extends Component {
    
    render() {
        
        // Render nothing if the "show" prop is false
        if(!this.props.show) {
          return null;
        }
        
        /*
        // The gray background
        const backdropStyle = {
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(0,0,0,0.3)',
          padding: 50
        };
        
        // The modal "window"
        const modalStyle = {
          backgroundColor: '#fff',
          borderRadius: 5,
          maxWidth: 500,
          minHeight: 300,
          margin: '0 auto',
          padding: 30
        };*/

        
        
/*          <div className="modalXX fadeXX">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  <h4 className="modal-title">Modal title</h4>
                </div>
                <div className="modal-body">
                  <p>One fine body&hellip;</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>*/
            {/*<div className="backdrop" style={backdropStyle}>
            <div className="modal" style={modalStyle}>
              {this.props.children}

              <div className="footer">
                <button onClick={this.props.onClose}>
                  Close
                </button>
              </div>
            </div>
          </div>*/}
          /*</div>*/

        
        
    var modalStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '70%',
        transform: 'translate(-50%, -50%)',
        zIndex: '9999',
        background: '#fff',
        padding: '20px'
      }
    
      if (this.props.width && this.props.height) {
        modalStyle.width = this.props.width + 'px'
        modalStyle.height = this.props.height + 'px'
        modalStyle.marginLeft = '-' + (this.props.width/2) + 'px',
        modalStyle.marginTop = '-' + (this.props.height/2) + 'px',
        modalStyle.transform = null
      }

      if (this.props.style) {
        for (var key in this.props.style) {
          modalStyle[key] = this.props.style[key]
        }
      }
    
      var backdropStyle = {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0px',
        left: '0px',
        zIndex: '9998',
        background: 'rgba(0, 0, 0, 0.5)'
      }
      
      if (this.props.backdropStyle) {
        for (var key in this.props.backdropStyle) {
          backdropStyle[key] = this.props.backdropStyle[key]
        }
      }

      return (
        <div className={this.props.containerClassName}>
          <div className={this.props.className} style={modalStyle}>
            {this.props.children}
          </div>
          {!this.props.noBackdrop &&
              <div className={this.props.backdropClassName} style={backdropStyle} onClick={this.props.onClose} />
          }
        </div>
      )
    }
}

export default Modal;