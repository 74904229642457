import React, { Component } from 'react';
import CommonDodo from './CommonDodo';
import DodoComponent from './DodoComponent';


class SubmitLocation extends Component {
  
  constructor() {
    super();
    
    this.state = {
      formState: 0 // cycles through 0, 1, 2
    };
    
    this.handleShowForm = this.handleShowForm.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }
  
  handleShowForm() {
    this.setState({formState: 1}); // currently showing form
  }
  
  handleDrop(acceptedFiles, rejectedFiles) {
    this.props.onPost(acceptedFiles[0]);
    this.setState({formState: 2}); // show success message for 10 seconds
    
    setTimeout(function() {
      this.setState({formState: 0}); // cycle back to initial screen
    }.bind(this), 10000);
  }
  
  handleSubmitForm() {
    var newComment = document.getElementById('newComment').value;
    
    if (newComment.trim().length > 0) {
      this.props.onPost(newComment);
      this.setState({formState: 2}); // show success message for 10 seconds
      
      setTimeout(function() {
        this.setState({formState: 0}); // cycle back to initial sreen
      }.bind(this), 10000);
    }
  }
  
  componentWillReceiveProps(props){
    if((!this.props || !props) || (!this.props.show && props.show)) {
      this.setState({formState: 0}); // reset if comment will just turn visible
    }
  }
  
  render(){
    if (!this.props.show) {
      return null;
    }
    
    // show form and wait for user to submit
    if (this.state.formState === 0)
    var formStyle = {
      lineHeight: '24px',
    };
    
    var submissionButtonStyle = {
      width: '100%',
      height: '60px',
      borderColor: '#93aacf',
      borderWidth: '2px',
      borderStyle: 'dashed',
      backgroundColor: '#EEF6FF',
      cursor: 'pointer',
    };
    
    var textStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '16pt',
      color: '#93aacf',
    };
    
    var labelStyle = {
      fontSize: '14pt',
      paddingTop: '15px',
      paddingBottom: '5px',
    }
    
    
    return (
      <div>
        
        <DodoComponent show="true">
        <div style={formStyle}>
            <div style={labelStyle}>Name of the gay spot you want to submit</div>
            <div><input type="text" className="form-control" placeholder="Name" id="name"></input></div>
            
            <div style={labelStyle}>Tell us more! What makes this place great?</div>
            <div><input type="text" className="form-control" placeholder="Description" id="description"></input></div>
            
            <div style={labelStyle}>Where can we find this? Such as location (latitude, longitude), link to Google maps, website, address etc.</div>
            <div><input type="text" className="form-control" placeholder="Location" id="location"></input></div>
        </div>
        </DodoComponent>
        
        <DodoComponent onClick={this.handleSubmitForm} show={true} fullComponentClickable='true' style='noborder'>
          <div style={submissionButtonStyle}><span style={textStyle}>Submit this spot</span></div>
        </DodoComponent>
        
      </div>
    )
    
    // show message on successful submission to user for 10 seconds
    if (this.state.formState === 2)
    return (
        <DodoComponent style="highlight" show="true">
        <div>Thanks! We will check your comment and publish it soon.</div>
        </DodoComponent>
    )

    // default case, ask user to post a comment
    return (
        <DodoComponent onClick={this.handleShowForm} button="left" buttonWidth="45px" fullComponentClickable="true" show="true">
        <div>Post a comment, share your thoughts!</div>
        <div className="glyphicon vertically-centered glyphicon-comment"></div>
        </DodoComponent>
    )
  }
}

export default SubmitLocation;