import React, { Component } from 'react';
import CommonDodo from './CommonDodo';
import imageBluedot from '../../icons/bluedot.png';
import imgMarker1 from '../../icons/marker1.png';
import imgMarker2 from '../../icons/marker2.png';
import imgMarker3 from '../../icons/marker3.png';
import imgMarker4 from '../../icons/marker4.png';
import imgMarker5 from '../../icons/marker5.png';
import imgMarker6 from '../../icons/marker6.png';
import imgMarker7 from '../../icons/marker7.png';
import imgMarker8 from '../../icons/marker8.png';
import imgMarker9 from '../../icons/marker9.png';


var gmap;
var markers = {};
var imageMarkers = [imgMarker1, imgMarker1, imgMarker2, imgMarker3, imgMarker4, imgMarker5, imgMarker6, imgMarker7, imgMarker8, imgMarker9]; // index i in interval [1, 9] will point to right image
var initialMap = {lat: 48.856, lng: 2.3522, zoom: 2};


class Map extends Component {
  
  constructor() {
    super();
    
    let lastPerimeter = null;
    
    this.handleMarkerTap = this.handleMarkerTap.bind(this);
  }
  
	componentDidMount(){
        
		// Only componentDidMount is called when the component is first added to
		// the page. This is why we are calling the following method manually. 
		// This makes sure that our map initialization code is run the first time.
        
        /*
        var styledMapType = new google.maps.StyledMapType(
        */
        
        var mapStyles = [
          {
            "featureType": "poi.business",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          }
        ];
        
        
        gmap = new GMaps({
			el: '#map',
            lat: initialMap.lat,
            lng: initialMap.lng,
            zoom: initialMap.zoom,
            streetViewControl: false,
            clickableIcons: false, // refers to standard points of interest
            styles: mapStyles,
            
            markerClusterer: function(map) {
                let markerCluster = new MarkerClusterer(map, [], {
                    title: 'Location Cluster',
                    maxZoom: 10,
                    disableDefaultUI: true,
                    imagePath: 'clustericons/' //'../../icons/cluster',
                });
                return markerCluster;
            }
        });
        if (this.props.setGmap) this.props.setGmap(gmap);
        
//        this.lastCenterZoom = this.props.centerZoom;
		this.componentDidUpdate();
        
        // get current position and center map accordingly if no query given
        if (!CommonDodo.queryFromUrl) {
            var self = this;
            GMaps.geolocate({
              success: function(position) {
                gmap.setCenter(position.coords.latitude, position.coords.longitude);
                gmap.setZoom(12);
                gmap.addMarker({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    icon: imageBluedot //'/icons/bluedot.png'
                });
                
                if (localStorage) {
                    localStorage.setItem('dodo-position', position.coords.latitude + ',' + position.coords.longitude);
                }
              },
              error: function(error) {
                // alert('Geolocation failed: '+error.message);
              },
              not_supported: function() {
                // alert("Your browser does not support geolocation");
              },
              always: function() {
                // alert("Done!");
              }
            });
        }
	}
    
    handleMarkerTap(e) {
        this.props.onLocationTap(e.details.hid);
    }
    
	componentDidUpdate(prevProps, prevState){
        
		/*if(this.lastLat == this.props.lat && this.lastLng == this.props.lng){
        
			// The map has already been initializrendered at this address.
			// Return from this method so that we don't reinitialize it
			// (and cause it to flicker).
            
			//return;
		}*/
        
        // only do this once (first time locations are ready)
        if ((prevProps == null || prevProps.locations == null || prevProps.locations.length === 0) && this.props.locations && this.props.locations.length > 0) {
//            console.log(this.props.locations.length + ' to update in ' + gmap);
            for (var i = 0; i < this.props.locations.length; i++) {
                var location = this.props.locations[i];
                if (location.type === 0) {
                  console.error('Error: Location ' + location.hid + ' has invalid type 0');
                }
                var marker = gmap.addMarker({
                    lat: location.latitude,
                    lng: location.longitude,
                    size: 'small',
                    zIndex: 100,
                    details: location,
                    icon: imageMarkers[location.type], //'/icons/marker'+ location.type + '.png',
                    click: this.handleMarkerTap
                });
                markers[location.hid] = marker; // attach marker to location
            }
        }
        
        // zoom in on the perimeter (usually given by the guide specified as external parameter)
        if (this.props.perimeter && this.props.perimeter !== this.lastPerimeter) {
            // specify area to be visible
            // areaBounds has format [{lat:, lng:}, {lat:, lng:}]
            if (this.props.perimeter.areaBounds) {
                gmap.fitLatLngBounds(this.props.perimeter.areaBounds);
            }
            // specify specific position to be visible (apply standard zoom level)
            // centerPosition has format {lat:, lng:}
            else if (this.props.perimeter.centerPosition) {
                gmap.setCenter(this.props.perimeter.centerPosition.lat, this.props.perimeter.centerPosition.lng);
                gmap.setZoom(this.props.perimeter.centerPosition.zoom);
            }
            // initial position
            else if (this.props.perimeter.initialMapPerimeter) {
                gmap.setCenter(initialMap.lat, initialMap.lng);
                gmap.setZoom(initialMap.zoom);
            }
            this.lastPerimeter = this.props.perimeter; // only do this once
        }
        
/*        // if property centerZoom is set, zoom in and center on given location
        if (this.lastCenterZoom !== this.props.centerZoom) {
            gmap.setCenter(this.props.centerZoom.latitude, this.props.centerZoom.longitude);
            gmap.setZoom(12);
            this.lastCenterZoom = this.props.centerZoom;
        }*/
        
        // TODO: Replace selectedLocation through selectedLocation.hid (as only hid needs to be known in Map)
        
        // display or un-display special marker for selected location
        if (this.props.selectedLocation !== this.lastSelectedLocation && (this.props.selectedLocation || this.lastSelectedLocation)) {
            
            // first de-activate previously selected marker
            if (this.lastSelectedLocation && this.lastSelectedLocation.hid) {
                var lastMarker = markers[this.lastSelectedLocation.hid];
                if (lastMarker) {
                    lastMarker.setAnimation(null);
                    lastMarker.setZIndex(101); // bring z-index back, but make sure it's still on top of all other regular markers
                }
            }
            
            // activate selected marker
            if (this.props.selectedLocation && this.props.selectedLocation.hid) {
                var marker = markers[this.props.selectedLocation.hid];
                if (marker) {
                    marker.setZIndex(102); // make sure it's in front of other markers
                    marker.setAnimation(google.maps.Animation.BOUNCE);
                }
            }
                /*marker.setIcon({url:(nowSelected)
                    ? '/icons/markerselected.png'
                    : '/icons/marker'+ this.lastSelectedLocation.type + '.png'});*/
            this.lastSelectedLocation = this.props.selectedLocation; // only do this once
        }
	}
    
	render(){
		return (
			<div style={{visibility:(this.props.show ? 'visible' : 'hidden'), display: (this.props.show ? 'block' : 'none')}} className={CommonDodo.standardCols + ' map-holder'}>
				<p>Gay map</p>
				<div id="map"></div>
			</div>
		);
	}
}

export default Map;