import React, { Component } from 'react';
import moment from 'moment';
import CommonDodo from './CommonDodo';
import DodoComponent from './DodoComponent';
import Gallery from './Gallery';
import quoteImage from '../../icons/quote.png';


class DetailView extends Component {
  
  componentDidUpdate() {
    if (this.props.show) {
        CommonDodo.scrollToDetailTop();
        /*
        // after rendering make sure the detailView is visible (scroll to detail view)
        if (CommonDodo.detailViewScrollOffset > 0) {
            // console.log('SCROLL TO: DetailView');
            window.scrollTo(0,  CommonDodo.detailViewScrollOffset);
        }*/
    }
  }
  
  render(){
    //if (this.props.location && this.props.location.comments) console.log('DetailView RENDER: ' + this.props.location.comments.length);

    if (!this.props.show) return null;
    if (!this.props.location || !this.props.location.entry) return null;

    var entry = this.props.location.entry;
    var summary = CommonDodo.locationTypeToString(entry.type);
    if (entry.address) { summary += " | " + entry.address; }

    var self = this;
    var listOfPhotos = null;
    if (this.props.location.photos) {
        listOfPhotos = this.props.location.photos.map(function(photoid) {
            var photourl = self.props.serverHost + '/locations/' + entry.hid + '/photos/' + photoid;
            var thumburl = self.props.serverHost + '/locations/' + entry.hid + '/photos/' + photoid + '/thumbnail';
            return {src: photourl, thumbnail: thumburl};
        });
    }

    var listOfComments = null;
    var parentStyle = { position: 'relative', marginTop: '5px' };
    var iconStyle = { position: 'absolute', top: 0, left: 0, width: '18px' };
    var dateStyle = { color: '#aaaaaa' };
    var commentStyle = { padding: '10px 0 0 25px' };
    var buttonWidth = '45px';
    var modifiedDateStyle = { color: '#969FA8' };
    var detailTitleStyle = CommonDodo.detailTitleStyle(buttonWidth);
    var detailLinesStyle = { lineHeight: '24px' };

    if (this.props.location.comments) {
        var i = 0;
        listOfComments = this.props.location.comments.map(function(comment) {
            i++;
            return <div style={parentStyle} key={i}>
                <div style={iconStyle}><img src={quoteImage}></img></div>
                <div style={commentStyle}>{comment.comment} <span style={dateStyle}>&mdash;&nbsp;{moment(comment.created).fromNow()}</span></div>
            </div>;
        });
    }

    var likeThis = null;
    if (entry.likes > 0) {
        if (this.props.location.youLike > 0) {
            likeThis = 'You and ' + (entry.likes-1) + ' others like this';
            if (entry.likes === 1) likeThis = 'You like this';
        }
        else likeThis = entry.likes + ' like this';
    }


    return (
        <DodoComponent button="right" buttonWidth={buttonWidth} fullComponentClickable="false" show="true">
        <div className="detail-text-paragraph">
            <div style={detailTitleStyle}>{entry.name}</div>
            <div>{entry.description} <span style={modifiedDateStyle}>&mdash;&nbsp;{'Modified ' + moment(entry.modified).fromNow() }</span>
            </div>
            <div style={detailLinesStyle}>
                <div>{summary}</div>
                {(entry.website) &&
                    <div><a target="_other" href={entry.website}>{entry.website}</a></div>
                }
                <div>{likeThis}</div>
            </div>

            <Gallery images={listOfPhotos} />

            {listOfComments}

        </div>

        <div>
            <div className={'glyphicon glyphicon-heart ' + (this.props.location.youLike ? 'youLikeThis' : '')} onClick={this.props.onLikeLocation}></div>
            <div className="glyphicon glyphicon-share-alt" onClick={this.props.onShare}></div>
        </div>
        </DodoComponent>
    )
  }
}

export default DetailView;