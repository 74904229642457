import React, { Component } from 'react';
import DodoComponent from './DodoComponent';
import CommonDodo from './CommonDodo';


// format content including meta-data tags for display
function formattedContent(originalContent, self) {
    var original = originalContent;
    
    // break paragraphs into individual strings
    var parabuffer = [];
    var paragraphs = original.split(/[\r\n]+/); // split at newlines
    
    for(var p = 0; p < paragraphs.length; p++) {
        var paragraph = paragraphs[p];
        
        // extract links and format correctly
        var fragbuffer = [];
        
        // start with interpreting fragment as JSON right away or on next turn
        var interpretAsJSON = (paragraph.charAt(0) === '{' || paragraph.charAt(0) === '}');
        var fragments = paragraph.split(/[{}]/); // split at { and }
        for(var f = 0; f < fragments.length; f++) {
            var fragment = fragments[f];
            if (fragment.length===0) continue; // ignore empty strings in split result
//                    console.log('...............................');
//                    console.log(fragment + ' (json ' + interpretAsJSON + ')');
            
            // text fragment
            if (!interpretAsJSON) fragbuffer.push(<span key={p + '.' + f}>{fragment}</span>);
            
            // JSON fragment (link, picture)
            else {
                try {
                    var item = eval('({' + fragment + '})');
                    var photostyle = {
                      display: 'block',
                      marginTop: '5px',
                      marginBottom: '5px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      maxWidth: '100%',
                    };
                    
                    // Link example: {hid:44444, name:’Link text'}
                    if (item.hid && item.name) {
                      fragbuffer.push(
                        <a href="#" id={item.hid} key={p + '.' + f} onClick={self.handleLinkTap}>{item.name}</a>
                      );
                    }
                    
                    // Photo example: {photoid:123, hid:44444} (hid can be 0 in case of guide-level photos not linked to single location)
                    else if (item.hid != null && item.photoid) {
                      fragbuffer.push(
                        <img key={p + '.' + f} style={photostyle} src={self.props.serverHost + '/locations/' + item.hid + '/photos/' + item.photoid}></img>
                      );
                    }
                    
                } catch(e) {} // catch errors silently and ignore
            }
            interpretAsJSON = !interpretAsJSON;
        }
        parabuffer.push(<div key={p}>{fragbuffer}</div>);
    }
    return parabuffer;
}


class Guide extends Component {

  constructor() {
    super();
    
    this.handleLinkTap = this.handleLinkTap.bind(this);
  }
  
    componentDidUpdate() {
        if (this.props.show) {
            CommonDodo.scrollToDetailTop();
            /*
            // after rendering make sure the detailView is visible (scroll to detail view)
            if (CommonDodo.detailViewScrollOffset > 0) {
                // console.log('SCROLL TO: DetailView');
                window.scrollTo(0,  CommonDodo.detailViewScrollOffset);
            }*/
        }
    }
    
    handleLinkTap(e) {
        var hid;
        var target = CommonDodo.xbtarget(e);
        if (target) hid = target.id;
        if (hid) this.props.onLinkTap(hid);
    }
    
	render(){
        
        if (!this.props.show) {
            return null;
        }
        
        if (!this.props.guide) {
            return null;
        }
        
        // change title displayed in browser
        document.title = this.props.guide.title;
        
        //var original = "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet here comes newline.\n\nLorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. If you've ever been to {hid:15591546,name:'Link text'}, you will keep coming back for more. The same is true for {hid:15591545,name:'Another link'}, right? Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.\n\n{photoid:24, hid:14106224}\n\nLorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.";
        
        var formatted = formattedContent(this.props.guide.text, this);
        
        return (
            <DodoComponent fullComponentClickable="false" show="true">
            <div className="detail-text-paragraph">
                <div style={CommonDodo.detailTitleStyle(0)}>{this.props.guide.title}</div>
                {formatted}
            </div>
            </DodoComponent>
        )
	}
}

export default Guide;