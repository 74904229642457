import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import CommonDodo from './CommonDodo';


class DodoComponent extends Component {
    componentDidUpdate() {
        // after rendering note the scroll offset for displaying detail views
        if (this.props.mainNavigation === 1) {
            if (CommonDodo.detailViewScrollOffset === 0) {
                var element = ReactDOM.findDOMNode(this);
                // scroll to slightly above nacvigation element
                if (element) CommonDodo.detailViewScrollOffset = Math.max(0, element.offsetTop - 10);
            }
        }
    }
    
	render(){
        
        var child1 = null, child2 = null;
        React.Children.map(this.props.children, function(child, index){
            if (index === 0) child1 = child;
            else if (index === 1) child2 = child;
            return;
        });
        
        if (!this.props.show) {
            return null;
        }
        
        if (!child1) {
            console.error('DodoComponent: Must have at least 1 child');
            return null;
        }
        
        var leftButton = (this.props.button === 'left');
        var rightButton = (this.props.button === 'right');
        var buttonWidth = (this.props.buttonWidth ? this.props.buttonWidth : "45px");
        var buttonHeight = (this.props.buttonHeight ? this.props.buttonHeight : null);
        var fullComponentClickable = (this.props.fullComponentClickable && this.props.fullComponentClickable.toLowerCase() === 'true');
        
        var parentStyle = { 
            minHeight: 0,
        	position: 'relative',
            // minHeight: '170px',
            textAlign: 'justify',
            textJustify: 'inter-word',
            lineHeight: '24px',
            border: '0.5px solid #CCCCCC'
        };
        if (this.props.border && this.props.border === '0') parentStyle.border = 0;
        if (this.props.style) {
            if (this.props.style === 'noborder') {
                parentStyle.border = 0;
                parentStyle.padding = 0; // make sure component stretches all across the box
            }
            else if (this.props.style === 'highlight') {
                parentStyle.border = '0.5px solid #e69900';
                parentStyle.backgroundColor = '#fff7e6';
            }
        }
        
        var contentStyle = {
            width: '100%',
            paddingTop: '12px',
            paddingRight: 0,
            paddingBottom: '12px',
            paddingLeft: 0
            // padding: 0, margin: '0 auto'
            // padding: '24px 0px 24px 0px' // '24px 65px 24px 30px'
        };
        
        var buttonStyle = {
            position: 'absolute',
            backgroundColor: '#EEF6FF',
            width: buttonWidth,
            height: (buttonHeight ? buttonHeight : '100%'),
            top: 0,
        };
        
        if (leftButton) {
            contentStyle['paddingLeft'] = buttonWidth;
            buttonStyle['left'] = 0;
        }
        
        // add padding on the right-hand side if button is present
        // do not add padding if button has fixed height (on top of button bar)
        if (rightButton) {
            if (!buttonHeight) contentStyle['paddingRight'] = buttonWidth;
            buttonStyle['right'] = 0;
        }
        
        var renderContent = (
            <div style={contentStyle}>
            {child1}
            </div>
        );
        
        var renderButton = null;
        if (leftButton || rightButton) {
            renderButton = (
                <div className={fullComponentClickable ? ' ' : 'link-component ' + (this.props.addClassName ? this.props.addClassName : '')} style={buttonStyle} onClick={(!fullComponentClickable ? this.props.onClick : function(){})}>
                {(child2) && child2}
                </div>
            );
        }
        
		return (
            <div className={CommonDodo.standardCols + ' ' + (this.props.addClassName ? this.props.addClassName : ' ') +  (fullComponentClickable ? ' link-component' : '')} style={parentStyle} onClick={(fullComponentClickable ?  this.props.onClick : function(){})}>
                {renderContent}
                {renderButton}
            </div>
		)
        
	}
}

export default DodoComponent;