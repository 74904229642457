import React, { Component } from 'react';
import DodoComponent from './DodoComponent';
import CommonDodo from './CommonDodo';
import Dropzone from 'react-dropzone';


class InputComment extends Component {
  
  constructor() {
    super();
    
    this.state = {
      formState: 0 // cycles through 0 (initial), 1 (show form), 2 (uploading), 3 (success), 4 (error)
    };
    
    this.handleShowForm = this.handleShowForm.bind(this);
    this.handleImageDrop = this.handleImageDrop.bind(this);
    this.handleSubmitComment = this.handleSubmitComment.bind(this);
  }
  
  handleShowForm() {
      this.setState({formState: 1}); // currently showing form
  }

  handleImageDrop(acceptedFiles, rejectedFiles) {
    this.uploadContent(acceptedFiles[0]);
  }
  
  handleSubmitComment() {
    var newComment = document.getElementById('newComment').value;
    if (newComment.trim().length > 0) {
      this.uploadContent(newComment);
    }
  }
  
  uploadContent(content) {
    const self = this;
    this.setState({formState: 2}, () => {
      this.props.onPost(content, function(success, error) {
        self.setState({formState: success ? 3 : 4}); // show success message for 10 seconds
        
        setTimeout(function() {
            self.setState({formState: 0}); // cycle back to initial sreen
        }, 8000); // show for 8 seconds
      });
    }); // show uploading message
  }
  
  componentWillReceiveProps(props){
      if((!this.props || !props) || (!this.props.show && props.show)) {
          this.setState({formState: 0}); // reset if comment will just turn visible
      }
  }

  render(){

      if (!this.props.show) {
          return null;
      }

      // photo input
      if (this.props.type==='image') {

          var imageUploadStyle = {
              width: '100%',
              height: '100px',
              borderColor: '#727272',
              borderWidth: '4px',
              borderStyle: 'dashed',
              backgroundColor: '#EFEFEF',
              cursor: 'pointer'
          };

          const textStyle = {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '16pt',
              color: '#727272'
          };
          
          if (this.state.formState === 1)
          return (
              <DodoComponent onClick={this.handleShowForm} fullComponentClickable="false" style="noborder" show="true">
              <div>
                  <Dropzone style={imageUploadStyle} accept='image/*' multiple={false} onDrop={this.handleImageDrop}>
                    {({getRootProps, getInputProps}) => (
                     <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <div style={textStyle}>Drag photo here or click to select</div>
                      </div>            
                    )}
                  </Dropzone>
              </div>
              </DodoComponent>
          )
          
          // show message on successful submission to user for 10 seconds
          if (this.state.formState >= 2 && this.state.formState <= 4) {
            let message = null;
            if (this.state.formState === 2) message = 'Uploading photo, please wait...';
            else if (this.state.formState === 3) message = 'Thanks! We will check your photo and publish it very soon.';
            else message = 'Oops, there has been an error, please try again later.';
            
            return (
                <DodoComponent style="highlight" show="true">
                <div>{message}</div>
                </DodoComponent>
            )
          }
          
          // default case, ask for user photo
          return (
              <DodoComponent onClick={this.handleShowForm} button="left" buttonWidth="45px" fullComponentClickable="true" show="true">
              <div>Upload a photo of this location!</div>
              <div className="glyphicon vertically-centered glyphicon-picture"></div>
              </DodoComponent>
          )
      }

      // Comment input
      else {
          // show form and wait for user to submit
          if (this.state.formState === 1)
          return (
              <DodoComponent onClick={this.handleSubmitComment} button="right" buttonWidth="100px" fullComponentClickable="false" show="true">
              <input type="text" className="form-control" placeholder="Your comment" id="newComment"></input>
              <div className="text-button vertically-centered">Submit</div>
              </DodoComponent>
          )
          
          // show message on successful submission to user for 10 seconds
          if (this.state.formState >= 2 && this.state.formState <= 4) {
            let message = null;
            if (this.state.formState === 2) message = 'Uploading, please wait...';
            else if (this.state.formState === 3) message = 'Thanks! We will check your comment and publish it very soon.';
            else message = 'Oops, there has been an error, please try again later.';
            
            return (
                <DodoComponent style="highlight" show="true">
                <div>{message}</div>
                </DodoComponent>
            )
          }

          // default case, ask user to post a comment
          return (
              <DodoComponent onClick={this.handleShowForm} button="left" buttonWidth="45px" fullComponentClickable="true" show="true">
              <div>Post a comment, share your thoughts!</div>
              <div className="glyphicon vertically-centered glyphicon-comment"></div>
              </DodoComponent>
          )
      }
  }
}

export default InputComment;