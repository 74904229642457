class CommonDodo {
  
  static locationTypeToString(type) {
      var types = ['Dummy', 'Bar', 'Club', 'Restaurant', 'Cafe', 'Hotel', 'Shopping', 'Beach, Sports', 'Cruising', 'Information'];
      if (1 <= type && type <= 9) return types[type];
      else return 'Undefined';
  }
  
  static formatBigNumber(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
  static detailTitleStyle(buttonWidth) {
      return {
          marginBottom: '12px',
          fontSize: '28px',
          textAlign: 'left',
          lineHeight: '34px',
          paddingRight: buttonWidth
      };
  }
  
  static scrollToDetailTop() {
      if (CommonDodo.detailViewScrollOffset > 0) {
          window.scrollTo(0,  CommonDodo.detailViewScrollOffset);
      }
  }
  
  // cross-browser target (https://stackoverflow.com/questions/1553661/how-to-get-the-onclick-calling-object)
  static xbtarget(e) {
      e = e || window.event;
      var targ = e.target || e.srcElement;
      if (targ.nodeType == 3) targ = targ.parentNode; // defeat Safari bug
      return targ;
  }
  
  static debugObject(o) {
      var out = '';
      for (var p in o) {
          out += p + ': ' + o[p] + '\n';
      }
      console.log(out);
  }
  
  static onNextFrame(callback) {
      setTimeout(function () {
          window.requestAnimationFrame(callback)
      }, 0);
  }
}

CommonDodo.detailViewScrollOffset = 0;
CommonDodo.queryFromUrl = null;
CommonDodo.standardCols = 'col-xs-12 col-md-10 col-md-offset-1';


export default CommonDodo;