import React, { Component } from 'react';
import CommonDodo from './CommonDodo';


class Gallery extends Component {
  
  constructor() {
    super();
    
    this.state = {
      selectedPhoto: null
    };
    
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  
  handleClick(photo) {
        if (photo) {
            var state = this.state;
            state.selectedPhoto = photo;
            this.setState(state);
        }
  }
    
  handleClose() {
      var state = this.state;
      state.selectedPhoto = null;
      this.setState(state);
  }

  render(){

      if (!this.props.images || this.props.images.length === 0) {
          return null;
      }

      var parentStyle = {
          overflow:'auto',
          paddingTop: '14px',
          paddingBottom: '14px'
      };

      var componentStyle = {
          padding: 0,
          margin: 0
      };

      var photoThumbnailStyle = {
          padding: 0,
          margin: 0,
          //objectFit: 'contain',
          //objectPosition: 'center',
          maxWidth: '98%',
      };

      var coverStyle = {
          zIndex: 99999,
          position:'fixed',
          padding:0,
          margin:0,
          top:0,
          left:0,
          width: '100%',
          height: '100%',
          background:'rgba(0,0,0,0.7)'
      };

      var photoDisplayStyle = {
          top: '50%',
          left: '50%',
          position: 'fixed',
          transform: 'translate(-50%,-50%)'
      };

      var self = this;
      var photoid = 0;
      var listOfPhotos = this.props.images.map(function(photo) {
          photoid++;
          return (
              <div style={componentStyle} className="col-xs-6 col-md-4 col-sm-4" key={photoid} onClick={self.handleClick.bind(self, photo)}>
                  <img style={photoThumbnailStyle} src={photo.thumbnail}></img>
              </div>
          )
      });


      return (
          <div style={parentStyle}>

              {listOfPhotos}

              {this.state.selectedPhoto && 
              <div style={coverStyle} onClick={this.handleClose}>
                  <img style={photoDisplayStyle} src={this.state.selectedPhoto.src}></img>
              </div>
              }

          </div>
      )
  }
}

export default Gallery;