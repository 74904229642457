import React, { Component } from 'react';
import CommonDodo from './CommonDodo';
import Modal from './Modal';


class ShareLink extends Component {
  
    selectText(e) {
        var field = CommonDodo.xbtarget(e);
        field.setSelectionRange(0, field.value.length);
    }
    
    render() {
        
        if(!this.props.show) {
          return null;
        }
        
        if (!this.props.location || !this.props.location.entry) {
            return null;
        }
        
        var modalStyle = {
            position: 'fixed',
            top: '50%',
            left: '50%',
            width: '70%',
            transform: 'translate(-50%, -50%)',
            zIndex: '9999',
            background: '#fff',
            padding: '20px'
        }
        
        var titleStyle = {
            textAlign: 'left',
            marginBottom: '20px'
        }
        
        var link = window.location.origin + '/' + this.props.location.entry.shortname;
        
        return (
            <Modal containerClassname="modal-dialog" className="modal-content" show={this.props.show} onClose={this.props.onClose}>
                <div style={titleStyle}>Direct link to {this.props.location.entry.name}:</div>
                <div><input type="text" readOnly className="form-control" value={link} onClick={this.selectText}></input></div>
            <div className="modal-footer"><button type="button" className="btn btn-default" onClick={this.props.onClose}>Got it</button></div>
            </Modal>
        )
    }
}

export default ShareLink;