import React, { Component } from 'react';
import CommonDodo from './CommonDodo';
import DodoComponent from './DodoComponent';


/*function onNextFrame(callback) {
    setTimeout(function () {
        window.requestAnimationFrame(callback)
    }, 0);
};*/

function summarize(text, maxlength) {
    if (text.length < maxlength) return [text, ''];
    
    var ellipsis = ' (...)';
    var pos = maxlength - ellipsis.length;
    while(text[pos] != ' ') pos--;
    return [text.substr(0, pos), ellipsis];
}

function listItem(self, entryOrGuide, id, title, subtitle, description) {
    var summary = summarize(description, 150);
    var description = <span>{ summary[0] + summary[1] }</span>;
    
    return (
        <DodoComponent key={entryOrGuide + ':' + id} button="right" buttonWidth="45px" onClick={self.handleSelect.bind(self, entryOrGuide, id)} fullComponentClickable="true" show="true">
        <div style={{lineHeight: '24px'}}>
            <div>{title}</div>
            <div className="text-gray">{subtitle}</div>
            <div>{description}</div>
        </div>
        <div className="glyphicon vertically-centered glyphicon-menu-right"></div>
        </DodoComponent>
    )
}


class LocationList extends Component {
    
    //lastLocations: null,
    //lastScrollOffset: 0,
    
    /*
	componentDidUpdate(prevProps, prevState) {
        
        // if just changed from non-visible to visible and still the same list, go back to last scroll position
        if (this.props.show && !prevProps.show && (this.lastLocations && this.lastLocations.length > 0) && this.props.locations == this.lastLocations) {
            var self = this;
            onNextFrame(function() {
                // console.log('SCROLL TO: LocationList');
                //window.scrollTo(0, self.lastScrollOffset);
            });
        }
    },
    
    handleSelectLocation(hid) {
        // remember list of locations to know if same list is later re-displayed
        //this.lastLocations = this.props.locations;
        // this.lastScrollOffset = window.pageYOffset;
        
        this.props.onSelectLocation(hid);
    },*/
    
    handleSelect(type, id) {
        if (type === 0) this.props.onSelectLocation(id);
        else if (type === 1) this.props.onSelectGuide(id);
    }
    
	render(){
        
        if (!this.props.show) return null;
        if (!this.props.content) return null;
        if (!this.props.content.locations && !this.props.content.guides) return null; // must have some content
        
        // entryOrGuide: 0 means entry, 1 means guide
		var self = this;
		var listOfGuides = null, listOfLocations = null;
        if (this.props.content.guides) listOfGuides = this.props.content.guides.map(function(guide, index) {
            return listItem(self, 1, guide.id, guide.title, 'Gay guide to ' + guide.name, guide.textShort);
		});
		if (this.props.content.locations) listOfLocations = this.props.content.locations.map(function(location, index) {
            return listItem(self, 0, location.hid, location.name, CommonDodo.locationTypeToString(location.type) + (location.likes > 0 ? ' | ' + location.likes + ' likes' : ''), location.description);
		});
        
		if(!listOfLocations.length) return null;
        
		return (
            <div>
            
            <DodoComponent style="noborder" show="true">
            <div style={CommonDodo.detailTitleStyle(0)}>{this.props.content.title}</div>
            </DodoComponent>
            
            {listOfGuides}
            {listOfLocations}
            
			</div>
		)
	}
}

export default LocationList;