import React, { Component } from 'react';
import CommonDodo from './CommonDodo';
import quoteImage from '../../icons/quote.png';


class News extends Component {
  
  constructor() {
    super();
    
    this.listOfStories = [];
    
    this.handleTap = this.handleTap.bind(this);
  }
  
  handleTap(entity, id) {
      if (entity === 'entry' || entity === 'comment') this.props.onSelectLocation(id);
      else if (entity === 'guide') this.props.onSelectGuide(id);
  }
  
  render(){
      
      if (!this.props.stories) return null;
      if (!this.props.show) return null;

      var quoteStyle = {
          height: '100pt',
          color: '#000000',
          fontSize: '14pt',
          textAlign: 'left',
          paddingLeft: '22pt',
          paddingRight: '5pt',
          paddingTop: '7pt'
      };

      // first time this is called remember selected stories and link styles
      if (this.listOfStories.length === 0) {
        var iconStyle = { position: 'absolute', top: 0, left: '5pt', width: '18px' };
        var selectedIndices = [];
        for (var j = 0; j < Math.min(4, this.props.stories.length); j++) {
            var index;
            do {
                index = Math.floor(Math.random() * this.props.stories.length);
            } while(selectedIndices.indexOf(index) >= 0); // keep looking for story that has not yet been selected
            selectedIndices.push(index);

            var story = this.props.stories[index];
            this.listOfStories.push(
                <a className={"col-xs-6 col-md-3 col-sm-3" + (index > 1 ? ' hidden-xs' : '')} style={{padding: 0}} key={story.entity + '-' + story.id} onClick={this.handleTap.bind(this, story.entity, story.id)}>
                    <div className='link-component-blue no-text-decoration' style={{ marginLeft: '1pt', marginRight: '1pt' }}>
                        <div style={iconStyle}><img src={quoteImage} /></div>
                        <div className='no-text-decoration' style={quoteStyle}>{story.promo}</div>
                    </div>
                </a>             
            );
        }
      }

      return (
          <div className={CommonDodo.standardCols} style={{padding: 0}}>
              {this.listOfStories}
          </div>
      )

  }
}

export default News;